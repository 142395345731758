import mediaCreator from '../mediaCreator';

export async function masterclassDataTransformer(data, dataProvider, notify, isLoading) {
  isLoading(true);
  const promises = [];

  if (undefined === data.complete) {
    data['complete'] = false;
  }
  if (undefined === data.disabled) {
    data['disabled'] = false;
  }

  [1, 2, 3].forEach((banner) => {
    if (data[`b${banner}`]) {
      promises.push(
        mediaCreator({
          dataProvider,
          notify,
          mediaFieldLabel: `banner${banner}`,
          fileLabel: `b${banner}`,
        })(data).then((d) => {
          data[`banner${banner}`] = d[`banner${banner}`];
        })
      );
    }
  });
  for (let index = 0; index < (data.items?.length ?? 0); index++) {
    promises.push(
      (async (i) => {
        const item = data.items[i];

        if (undefined === item.premium) {
          item['premium'] = false;
        }
        if (undefined === item.disabled) {
          item['disabled'] = false;
        }

        if (!item.media) {
          item.media = {};
        }

        if (item.poster || item.video) {
          const m = { ...data.items[i].media };
          let posterUpload = item;
          try {
            const createPoster = mediaCreator({
              dataProvider,
              notify,
              mediaFieldLabel: 'poster',
              fileLabel: 'poster',
              suppressError: false,
            });
            posterUpload = await createPoster(item);
          } catch (e) {
            throw `Impossibile caricare il poster della masterclass "${data.items[i].name}" [ID: ${data.items[i].id}].\nERRORE: "${e}"`;
          }
          let result = posterUpload;
          try {
            const createVideo = mediaCreator({
              dataProvider,
              notify,
              mediaFieldLabel: 'path',
              fileLabel: 'video',
              suppressError: false,
            });
            result = await createVideo(posterUpload);
          } catch (e) {
            throw `Impossibile caricare il video della masterclass "${data.items[i].name}" [ID: ${data.items[i].id}].\nERRORE: "${e}"`;
          }
          if (result.poster) {
            data.items[i].media.poster = result.poster;
          }
          if (result.path) {
            data.items[i].media.path = result.path;
          }
          if (m.path && !data.items[i].media.path) {
            data.items[i].media.path = m.path;
          }
          if (m.poster && !data.items[i].media.poster) {
            data.items[i].media.poster = m.poster;
          }
        }
      })(index)
    );
  }
  await Promise.all(promises)
    .finally(() => {
      isLoading(false);
    })
    .catch((errorMessage) => {
      isLoading(false);
      notify(`Errore inatteso: ${errorMessage}`, 'error');
      throw errorMessage;
    });
  return data;
}
